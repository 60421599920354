import { render, staticRenderFns } from "./DonationsShow.vue?vue&type=template&id=abc662a8&scoped=true&"
import script from "./DonationsShow.vue?vue&type=script&lang=js&"
export * from "./DonationsShow.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  var style0 = require("./DonationsShow.vue?vue&type=style&index=0&id=abc662a8&lang=sass&scoped=true&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "abc662a8",
  "15657b8e"
  
)

export default component.exports