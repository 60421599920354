import { render, staticRenderFns } from "./Donations.vue?vue&type=template&id=34f99007&scoped=true&"
import script from "./Donations.vue?vue&type=script&lang=js&"
export * from "./Donations.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  var style0 = require("./Donations.vue?vue&type=style&index=0&id=34f99007&lang=sass&scoped=true&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "34f99007",
  "37b868e4"
  
)

export default component.exports