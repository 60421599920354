<template>
  <div class="donations-show">
    <Embed :fdsData="fdsData" />
  </div>
</template>

<script>
import Embed from './Embed'

const FDS_TYPE = 'profile'

export default {
  name: 'DonationsShow',

  props: {
    id: {
      type: [String, Number],
      required: true
    }
  },

  components: {
    Embed
  },

  computed: {
    fdsData() {
      return {
        type: FDS_TYPE,
        'profile-id': this.id
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.donations-show
</style>
