<template>
  <div class="page-donation">
    <PageContainer :banner="false">
      <DonationsShow :id="id" />
    </PageContainer>
  </div>
</template>

<script>
import page from '@/mixins/page'

import DonationsShow from '@/components/donations/DonationsShow'
import PageContainer from '@/components/PageContainer'

export default {
  name: 'Donation',

  mixins: [page],

  props: {
    id: {
      type: [String, Number],
      required: true
    }
  },

  components: {
    PageContainer,
    DonationsShow
  }
}
</script>

<style lang="sass" scoped>
.page-donation
</style>
